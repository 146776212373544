import React from 'react'
import  { Footer, Navbar } from '../components';
import './about.css'

const About = () => {
  return (
    <div className="App">
      <Navbar/>
        <div>
          <h1>A propos d'Etheryo...</h1>
        </div>
      <Footer/>
    </div>
  )
}

export default About