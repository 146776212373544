import React, {useEffect,useState} from 'react'
import './navbar.css'
import { NavLink } from "react-router-dom";
import { mdiCube,mdiSchool,mdiMemory,mdiFileDocument,mdiFire,mdiAlarm,mdiFerry } from '@mdi/js';
import logoFull from '../../../assets/logo_filled192.png'
import logoBlack from '../../../assets/logo_black192.png'
import Icon from '@mdi/react';

//--------------------------------
// JavaScript functions
//--------------------------------

const dropDownState = new Map();

function setElementColor(givenColor, querySelector)
{
  var navbarNode = document.querySelectorAll(querySelector);
  for(var i=0; i<navbarNode.length ; i++)
  {
    navbarNode[i].style.color = givenColor;
  }
}

function changeCSSVariable(variableName,newValue)
{
  var r = document.querySelector(':root');
  r.style.setProperty(variableName, newValue);
}

function showDropDown(id, liTagNum)
{
  const dropdown = document.getElementsByClassName("etheryo__navbar_dropdown-menu")[id];
  if((dropDownState.get(id) == null))
  {
    dropdown.style.display = "flex";
    const widthElementLi = document.querySelectorAll(".etheryo__navbar_link li")[liTagNum].offsetWidth;
    const widthElementDropdown = dropdown.offsetWidth;
    const calculus = -(widthElementDropdown-widthElementLi)/2;
    dropdown.style.left = dropdown.offsetLeft+calculus+"px"; /*To be aligned with the text*/
    dropdown.style.opacity = "100";
    dropdown.style.transform = "translateY(1.5rem)";
    dropDownState.set(id,true);
  }
  else if((dropDownState.get(id) === false))
  {
    dropdown.style.display = "flex";
    dropdown.classList.add("slide-bottom")
    dropDownState.set(id,true);
  } else {
    dropdown.classList.add("slide-top")
    dropDownState.set(id,false);
  }
}

function dropDownAnimationStatus(id)
{
  const dropdown = document.getElementsByClassName("etheryo__navbar_dropdown-menu")[id];
  if((dropDownState.get(id) === false) || (dropDownState.get(id) == null))
  {
    dropdown.style.transform = "translateY(1.5rem)";
    dropdown.classList.remove("slide-top")
    dropdown.style.display = "none";
  } else {
    dropdown.classList.remove("slide-bottom")
  }
}

//--------------------------------
// JSX Elements
//--------------------------------

const DropdownItems = ( {itemId,dropDownId=itemId,name,itemsDropdown} ) => {
  return(
    <>
      <a href='#0' onClick={() => showDropDown(dropDownId,itemId)} >{name}</a>
      <div className='etheryo__navbar_dropdown-menu slide-bottom' onAnimationEnd={() => dropDownAnimationStatus(dropDownId)}>
      {
      itemsDropdown.map((item, it) => {
        //the key could be modified with a good library. For now we let like this.
      return <a href={item[2]} key={it}><Icon path={item[1]} size={1} />{item[0]}</a>
      })
      }
      </div>
    </>
  )
}

const MenuItems = () => { 
  const Logiciels = [["Feu Toulouse",{mdiFire}.mdiFire,"logiciels/feu-toulouse"],
                   ["BattleShip TUI",{mdiFerry}.mdiFerry,"logiciels/battleship-tui"],
                   ["Dumb Twitch Clock",{mdiAlarm}.mdiAlarm,"logiciels/dtc"]];
  const Projets = [["INSA Toulouse",{mdiSchool}.mdiSchool,"http://insa.etheryo.fr"],
                  ["Minecraft Server",{mdiCube}.mdiCube,"http://mc.etheryo.fr"],
                  ["Raspberry Pi",{mdiMemory}.mdiMemory,"http://rspi.etheryo.fr"],
                  ["Mon CV",{mdiFileDocument}.mdiFileDocument,"http://yohanboujon.etheryo.fr"]];
  return(
    <div className='etheryo__navbar_link'>
      <ul>
        <li>
          <DropdownItems itemId="0" name="Logiciels" itemsDropdown={Logiciels} />
        </li>
        <li>
          <DropdownItems itemId="1" name="Projets" itemsDropdown={Projets} />
        </li>
        <li>
          <a href='articles'>Articles</a>
        </li>
      </ul>
    </div>
  )
}

const Navbar = () => {
  //----------------------------------------
  //useState for the Scrolling behavior
  //----------------------------------------
  const [scrollTop, setScrollTop] = useState(0);
  
  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //----------------------------------------
  //useState for the DOM loading
  //----------------------------------------
  const [changeColorNavbar, setchangeColorNavbar] = useState(false);

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setchangeColorNavbar(true);
    };

  // Check if the page has already loaded
  if (document.readyState === 'complete') {
    onPageLoad();
  } else {
    window.addEventListener('load', onPageLoad);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener('load', onPageLoad);
  }
}, []);

if(changeColorNavbar)
{
  if(scrollTop > 30)
  {
    document.getElementsByClassName("etheryo__navbar")[0].style.backgroundColor = "rgba(255, 210, 255, 0.4)";
    document.getElementsByClassName("etheryo__navbar")[0].style.boxShadow =  "0 14px 28px rgba(109, 71, 143,0.25), 0 10px 10px rgba(109, 71, 143,0.22)";
    changeCSSVariable("--blur-effect","blur(16px)");
    changeCSSVariable("--navbar-height","4rem");
    changeCSSVariable("--navbar-link-color","black");
    changeCSSVariable("--hover-navbar-color","rgba(156, 39, 176, 0.15)");
  } else {
    document.getElementsByClassName("etheryo__navbar")[0].style.backgroundColor = "rgba(255, 255, 255, 0)";
    document.getElementsByClassName("etheryo__navbar")[0].style.boxShadow =  "";
    changeCSSVariable("--blur-effect","blur(0px)");
    changeCSSVariable("--navbar-height","5rem");
    changeCSSVariable("--navbar-link-color","white");
    changeCSSVariable("--hover-navbar-color","rgba(255, 255, 255, 0.15)");
  }
};

  return (
    <>
      <nav className="etheryo__navbar">
        <NavLink className="etheryo__navbar_logo" to="/">
          {scrollTop > 30? <img src={logoBlack} alt="logo"/> : <img src={logoFull} alt="logo"/>}
        </NavLink>
        <MenuItems/>
      </nav>
  </>
  )
}

export default Navbar