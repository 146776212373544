import React from 'react';
import  { Main, About, NotFound } from './pages';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import './App.css'

const App = () => {
  return (
    <Router>
    <Routes>
        <Route exact path='/' element={<Main/>} />
        <Route path='/about' element={<About/>} />
        <Route path='*' element={<NotFound />}/>
    </Routes>
    </Router>
  )
}

export default App