import React from 'react'
import  { Footer, Navbar } from '../components';
import './main.css'
import MainImg from '../../assets/background-main.jpg';

const Main = ( {test} ) => {
  return (
    <div className="App">
      <Navbar/>
        <div className='etheryo__title' style={{ backgroundImage: `url(${MainImg})` }}>
          <div className='etheryo__navbar_notfixed'></div>
          <div className='etheryo__title-container'>
            <h1>Bienvenue sur Etheryo !</h1>
            <span>Une plateforme avec des logiciels développés sous divers langages, des liens externes vers différents projets ainsi que des articles sur divers thèmes.</span>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Main