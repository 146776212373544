import React from 'react'
import  { Footer, Navbar } from '../components';
import './notfound.css'

const NotFound = () => {
  return (
    <div className="App">
      <Navbar/>
        <div className='etheryo__notfound'>
          <h1>Page Non trouvée !</h1>
        </div>
      <Footer/>
    </div>
  )
}

export default NotFound